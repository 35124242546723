import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SwipableViews from 'react-swipeable-views';
import cn from 'classnames';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { initI18n } from '../../../utils';
import { ThemeProvider, useTheme } from '../../../theme';
import { Locale } from '../../../types';
import { useMemo } from 'react';
import FareCode from '../FareCode/FareCode';
import SegmentTabs from '../../../SegmentTabs/SegmentTabs';
import { textIsLink } from '../../utils';
initI18n('FareConditions');
initI18n('MobileStepbar');
const FareConditions = ({ onLangChange, showTranslateTab, fareRulesBySegmentsPassengersMap, tabsSegments, selectLang, setSegment, segment, selectedLang, hasErrors, firstPassengerInList, fareRules }) => {
    var _a;
    const { FareConditions: css } = useTheme('FareConditions');
    const { t } = useTranslation('FareConditions');
    const handleLangChange = lang => {
        if (lang === selectedLang) {
            return;
        }
        onLangChange(lang);
        selectLang(lang);
    };
    const fareRulesSuccessfullyParsed = useMemo(() => Object.keys(fareRulesBySegmentsPassengersMap).length > 0, [
        fareRulesBySegmentsPassengersMap
    ]);
    const segmentTabTheme = useMemo(() => ({
        SegmentTabs: {
            SegmentTabs: {
                wrapper: css.segmentTabsWrapper,
                title: css.segmentTabsTitle,
                segments: css.segmentTabsSegments,
                inner: css.segmentTabsInner
            },
            SegmentTab: { segment_active: css.segmentTabsTab__active }
        }
    }), []);
    const ruleTextRender = (text, index) => {
        return (React.createElement("div", { key: index }, textIsLink(text) ? (React.createElement("a", { href: text, target: "_blank" }, t('See more details on this page'))) : (React.createElement("pre", { dangerouslySetInnerHTML: { __html: text } }))));
    };
    return (React.createElement(Paper, { className: css.paperRoot },
        fareRulesSuccessfullyParsed && (React.createElement(ThemeProvider, { value: segmentTabTheme },
            React.createElement(SegmentTabs, { title: t('Fare conditions'), segments: tabsSegments, isCompact: false, scrollToActive: true, sticky: true, onSegmentClick: segmentId => {
                    setSegment(segmentId);
                }, activeSegment: segment, classNameTab: css.segmentTabsTab, segmentsClassName: css.segmentsTabs }))),
        React.createElement("div", { className: cn(css.tabsWrp, { [css.tabsWrp_error]: hasErrors }) }, showTranslateTab && (React.createElement(Tabs, { value: selectedLang, classes: {
                root: css.translateTabs,
                flexContainer: css.translateTabsContainer,
                indicator: cn(css.tabIndicator, css.translateTabIndicator)
            } },
            React.createElement(Tab, { classes: {
                    root: cn(css.tab, css.translateTab),
                    selected: cn(css.tab_active, css.translateTab_selected)
                }, onClick: () => handleLangChange(Locale.English), label: t('Original') }),
            React.createElement(Tab, { classes: {
                    root: cn(css.tab, css.translateTab),
                    selected: cn(css.tab_active, css.translateTab_selected)
                }, onClick: () => handleLangChange(Locale.Russian), label: t('Translate to russian*') })))),
        React.createElement(React.Fragment, null,
            React.createElement(SwipableViews, { index: Object.keys(fareRulesBySegmentsPassengersMap).indexOf(segment), onChangeIndex: index => {
                    setSegment(Object.keys(fareRulesBySegmentsPassengersMap)[index]);
                } }, Object.keys(fareRulesBySegmentsPassengersMap).map(key => (React.createElement("div", { key: key, className: css.fareConditionsContent },
                React.createElement("div", null,
                    React.createElement("pre", null, segment && (React.createElement(FareCode, { passengersRules: fareRulesBySegmentsPassengersMap[segment] }))),
                    segment &&
                        fareRulesBySegmentsPassengersMap[segment][firstPassengerInList].rules.map((rule, index) => ruleTextRender(rule.text, index))))))),
            !fareRulesSuccessfullyParsed && (React.createElement("div", { className: css.fareConditionsContent },
                React.createElement("div", null, (_a = fareRules.FareRules.rulesForSegments) === null || _a === void 0 ? void 0 : _a.map(rule => rule.rules.map((rule, index) => ruleTextRender(rule.text, index)))))))));
};
export default FareConditions;
