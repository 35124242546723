import { useCallback, useRef } from 'react';
import { useToggleable } from '../../../hooks';
import { scrollTo } from '../../../utils';
export const useConsentsCheckbox = ({ needConfirm = false } = {}) => {
    const checkboxRef = useRef(null);
    const { isOpen: isChecked, setOpen: setChecked } = useToggleable(false);
    const { isOpen: isOpenTooltip, open: onOpenTooltip, close: onCloseTooltip } = useToggleable(false);
    const isTooltipOpen = isOpenTooltip && !isChecked;
    const isNeedConfirm = !isChecked && needConfirm;
    const onChange = useCallback((_, checked) => setChecked(checked), [
        setChecked
    ]);
    const onConfirm = useCallback(() => {
        if (!isNeedConfirm) {
            return;
        }
        scrollTo(checkboxRef.current.offsetTop);
        onOpenTooltip();
    }, [isNeedConfirm, checkboxRef, onOpenTooltip]);
    return {
        // data
        checkboxRef,
        isChecked,
        isTooltipOpen,
        isNeedConfirm,
        // methods
        setChecked,
        onOpenTooltip,
        onCloseTooltip,
        onChange,
        onConfirm
    };
};
