import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaggageType } from '@websky/graphql';
import { useTheme } from '../../../../../theme';
import { getActualBaggage, getSegmentsInBaggageGroup, isCanModifyServices } from '../../../utils';
import { getDisabled } from '../../../store/selector';
import { setServices } from '../../../store/actions';
import PassengerBaggageRadio from '../PassengerBaggageRadio/PassengerBaggageRadio';
import PassengerBaggageCounter from '../PassengerBaggageCounter/PassengerBaggageCounter';
import BaggageCounter from '../../../../BaggageCounter';
const PassengerBaggageControl = ({ baggageServices, passenger, segmentId, isUnavailable }) => {
    const { BaggagePassenger: theme } = useTheme('UpgradeBaggageDialog');
    const dispatch = useDispatch();
    const isDisabled = useSelector(getDisabled);
    const isAllSegments = !segmentId;
    const actualBaggage = useMemo(() => {
        return getActualBaggage(baggageServices, !isUnavailable ? segmentId : undefined, false);
    }, [baggageServices, segmentId, isUnavailable]);
    const segmentIds = useMemo(() => {
        var _a;
        if (!(actualBaggage === null || actualBaggage === void 0 ? void 0 : actualBaggage.segmentIds)) {
            return [];
        }
        if (isAllSegments) {
            return [...getSegmentsInBaggageGroup(baggageServices)];
        }
        return (_a = actualBaggage.segmentIds.find(ids => ids.includes(segmentId))) !== null && _a !== void 0 ? _a : [];
    }, [baggageServices, actualBaggage, segmentId, isAllSegments]);
    const isCanModify = useMemo(() => isCanModifyServices(baggageServices, passenger), [baggageServices, passenger]);
    const disabled = isDisabled || isUnavailable || !isCanModify || !actualBaggage.canBeAdded;
    const controlProps = {
        segmentId,
        segmentIds,
        disabled,
        passengerId: passenger.id,
        baggages: baggageServices
    };
    const onChange = useCallback((type) => (items) => {
        dispatch(setServices({ items, type }));
    }, [dispatch]);
    if (!actualBaggage) {
        return (React.createElement("div", { className: theme.counter_wrapper },
            React.createElement(BaggageCounter, { disabled: true })));
    }
    if ([BaggageType.BaggageExcess].includes(actualBaggage.type)) {
        return React.createElement(PassengerBaggageRadio, Object.assign({}, controlProps, { onChange: onChange('radio') }));
    }
    return React.createElement(PassengerBaggageCounter, Object.assign({}, controlProps, { onChange: onChange('counter') }));
};
export default PassengerBaggageControl;
